import './App.css';
import Profile from './Profile.js';

function App() {
  return (
    <div>
      <Profile />
    </div>
  );
}

export default App;
