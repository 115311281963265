import React from "react";

class Profile extends React.Component {
    render() {
        return(
            <div class="mx-auto w-screen p-20 space-y-5 font-mono">
                <img 
                    src="/images/icecreaminu-pfp.png" 
                    alt="Fursona Icon by Cinnamonspots"
                    className="rounded-full max-w-s mx-auto" 
                />
                <h1 className="mx-auto sm:w-max font-bold text-center text-2xl dark:text-gray-100">
                    Russell Sprout
                </h1>

                {/* <p className="mx-auto sm:w-4/12 font-light text-center dark:text-gray-100">
                    Hewwo, my name is Russell~ I'm a shiba dog 🐕🐶💻. I like reading newspapers 🗞️ walking on the beach 🌴 and trying new foods 🍛.
                    I'm gay 👨‍👨‍👧 and my pronouns are he/him 🌈. I live in the United States 🇺🇸
                </p> */}

                <ul className="mx-auto sm:w-max space-y-2">
                    <li className="mx-auto w-max">
                        <a href="https://twitter.com/icecreaminu"
                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 dark:text-blue-300 dark:hover:text-blue-500 dark:visited-purple-200"
                        target="_blank"
                        rel="noreferrer"
                        >
                            Twitter
                        </a>
                    </li>
                    <li className="mx-auto w-max">
                        <a href="https://t.me/icecreaminu"
                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 dark:text-blue-300 dark:hover:text-blue-500 dark:visited-purple-200"
                        target="_blank"
                        rel="noreferrer"
                        >
                            Telegram
                        </a>
                    </li>
                    <li className="mx-auto w-max">
                        <a href="https://t.me/addstickers/RussellTheShibe"
                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 dark:text-blue-300 dark:hover:text-blue-500 dark:visited-purple-200"
                        target="_blank"
                        rel="noreferrer"
                        >
                            Telegram Sticker Pack
                        </a>
                    </li>
                    <li className="mx-auto w-max">
                        <a href="/images/icecreaminu-refsheet.png"
                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 dark:text-blue-300 dark:hover:text-blue-500 dark:visited-purple-200"
                        target="_blank"
                        rel="noreferrer"
                        >
                            Reference Sheet
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Profile;
